import {
	replace,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { Form } from '@remix-run/react'
import { MoonhubLogo } from '#app/components/logo'
import { Button } from '#app/components/ui/button'
import { GradientText } from '#app/components/ui/text'
import { getUserId } from '#app/utils/auth.server'

export const meta: MetaFunction = () => [
	{ title: 'AI-Driven Recruiting Services - Moonhub' },
]

export async function loader({ request }: LoaderFunctionArgs) {
	const userId = await getUserId(request)

	// if we have a user id, redirect to the dashboard
	if (userId) {
		return replace('/org')
	}

	return {}
}

export default function Index() {
	return (
		<main className="font-poppins grid h-screen place-items-center overflow-hidden p-10">
			<div className="landscape:space-10 grid w-full grid-cols-1 items-center gap-6 px-4 portrait:grid-cols-1 portrait:gap-6 md:portrait:gap-24 landscape:grid-cols-5 lg:landscape:gap-24 xl:landscape:gap-32">
				<div className="flex items-center justify-center portrait:h-[min(40vh,400px)] landscape:col-span-2 landscape:h-[min(70vh,600px)]">
					<img
						src="/img/moonhub-hero-helmet.png"
						alt="Moonhub"
						className="h-full w-auto object-contain"
					/>
				</div>
				<div className="flex flex-col items-center justify-center gap-6 portrait:items-center landscape:col-span-3 landscape:items-start">
					<MoonhubLogo className="h-10 animate-slide-left text-white [animation-delay:0.5s] [animation-fill-mode:backwards]" />
					<h1
						data-heading
						className="animate-slide-left text-center text-6xl font-medium text-foreground [animation-delay:0.8s] [animation-fill-mode:backwards] portrait:text-center portrait:text-[3rem] landscape:text-left"
					>
						<GradientText variant="moonhub" reverse>
							Autopilot, with Stella
						</GradientText>
					</h1>
					<Form action="/auth/auth0" method="post" className="mt-6">
						<Button variant="default" type="submit" size="2xl">
							Log In
						</Button>
					</Form>
				</div>
			</div>
		</main>
	)
}
